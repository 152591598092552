import React from 'react';
import { FaBox, FaTruck, FaPassport, FaPlane, FaShoppingCart } from 'react-icons/fa';
import './ServicesGrid.css';

const services = [
    { icon: <FaBox />, title: 'Import & Export', description: 'Efficient handling of international trade operations.' },
    { icon: <FaTruck />, title: 'Haulage', description: 'Reliable transportation of goods across regions.' },
    { icon: <FaPassport />, title: 'Customs Brokerage', description: 'Expert navigation of customs regulations and procedures.' },
    { icon: <FaPlane />, title: 'Freight Forwarding', description: 'Comprehensive logistics solutions for air and sea freight.' },
    { icon: <FaShoppingCart />, title: 'Commodity Procurement', description: 'Sourcing and supply of essential commodities.' }
];

function ServicesGrid() {
    return (
        <section className="services py-16 bg-gray-100">
            <div className="container mx-auto px-4">
                <h2 className="text-3xl font-bold text-center mb-4">Services</h2>
                <p className="text-center mb-12">Our commitment is to ensure client satisfaction by delivering top-notch services at competitive prices. We offer customer service that distinguishes us from others and leverage advanced technology to guarantee safe and secure delivery of your orders.</p>
                <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-8">
                    {services.map((service, index) => (
                        <div key={index} className="service-card bg-white p-6 rounded-lg shadow-md text-center">
                            <div className="service-icon text-4xl mb-4">{service.icon}</div>
                            <h3 className="text-xl font-semibold mb-2">{service.title}</h3>
                            <p>{service.description}</p>
                        </div>
                    ))}
                </div>
            </div>
        </section>
    );
}

export default ServicesGrid; 