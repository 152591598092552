import React from 'react';
import { Carousel } from 'react-responsive-carousel';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import './HeroSection.css';

function HeroSection() {
    return (
        <section className="hero relative">
            <Carousel
                showArrows={true}
                autoPlay={true}
                infiniteLoop={true}
                showThumbs={false}
                showStatus={false}
                className="w-full"
            >
                <div>
                    <img src={`${process.env.PUBLIC_URL}/assets/slidez/1.png`} alt="Sea" />
                </div>
                <div>
                    <img src={`${process.env.PUBLIC_URL}/assets/slidez/2.jpg`} alt="Airplane" />
                </div>
                <div>
                    <img src={`${process.env.PUBLIC_URL}/assets/slidez/3.jpg`} alt="Warehouse" />
                </div>
                
                <div>
                    <img src={`${process.env.PUBLIC_URL}/assets/slidez/sf1.jpg`} alt="Warehouse" />
                </div>

                <div>
                    <img src={`${process.env.PUBLIC_URL}/assets/slidez/sf2.jpg`} alt="Warehouse" />
                </div>
                <div>
                    <img src={`${process.env.PUBLIC_URL}/assets/slidez/g1.jpg`} alt="Warehouse" />
                </div>

            </Carousel>
            <div className="hero-overlay absolute inset-0 flex flex-col items-center justify-center text-center text-white">
                <h1 className="text-4xl font-bold mb-4">Your Global Export & Import Partner</h1>
                <p className="text-lg mb-6">Ensuring your products reach global markets swiftly and securely. Partner with us for seamless international trade.</p>
                <div className="flex space-x-4">
                    <a href="#read-more" className="btn-primary">Read More</a>
                    <a href="#contact" className="btn-secondary">Contact Us</a>
                </div>
            </div>
        </section>
    );
}

export default HeroSection; 