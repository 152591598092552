import React from 'react';
import './Header.css';

function Header() {
    return (
        <header className="header">
            <div className="nav-bar">
                <div className="logo">
                    <img src="/assets/images/logo.png" alt="Logo" className="logo-img" />
                </div>
                <nav className="nav-links">
                    <a href="#home" className="nav-link">Home</a>
                    <a href="#about" className="nav-link">About Us</a>
                    <a href="#products" className="nav-link">Products</a>
                    <a href="#gallery" className="nav-link">Gallery</a>
                </nav>
            </div>
        </header>
    );
}

export default Header; 