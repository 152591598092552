import React from 'react';
import Header from './components/Header';
import HeroSection from './components/HeroSection';
import ServicesGrid from './components/ServicesGrid';
import SocialMediaSidebar from './components/SocialMediaSidebar';
import Footer from './components/Footer';

function App() {
    return (
        <div>
            <Header />
            <HeroSection />
            <ServicesGrid />
            <SocialMediaSidebar />
            <Footer />
        </div>
    );
}

export default App;
