import React, { useState } from 'react';
import { FaFacebookF, FaInstagram, FaTwitter, FaLinkedinIn, FaWhatsapp, FaBars } from 'react-icons/fa';
import './SocialMediaSidebar.css';

function SocialMediaSidebar() {
    const [isOpen, setIsOpen] = useState(false);

    const toggleSidebar = () => {
        setIsOpen(!isOpen);
    };

    return (
        <div className={`social-media-sidebar ${isOpen ? 'open' : ''}`}>
            <button className="toggle-button" onClick={toggleSidebar}>
                <FaBars />
            </button>
            {isOpen && (
                <div className="icons">
                    <a href="#" className="social-icon facebook"><FaFacebookF /></a>
                    <a href="#" className="social-icon instagram"><FaInstagram /></a>
                    <a href="#" className="social-icon twitter"><FaTwitter /></a>
                    <a href="#" className="social-icon linkedin"><FaLinkedinIn /></a>
                    <a href="#" className="social-icon whatsapp"><FaWhatsapp /></a>
                </div>
            )}
        </div>
    );
}

export default SocialMediaSidebar; 