import React from 'react';
import './Footer.css';

function Footer() {
    return (
        <footer className="footer">
            <div className="footer-content">
                <div className="footer-links">
                    <a href="#home">Home</a>
                    <a href="#about">About Us</a>
                    <a href="#services">Services</a>
                    <a href="#contact">Contact</a>
                </div>
                <div className="footer-social">
                    <a href="#facebook">Facebook</a>
                    <a href="#twitter">Twitter</a>
                    <a href="#linkedin">LinkedIn</a>
                </div>
                <div className="footer-contact">
                    <p>Contact us: favsa22@gmail.com</p>
                </div>
            </div>
        </footer>
    );
}

export default Footer; 